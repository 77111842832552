import request from "@/utils/request";

/**
 * 查询商品列表
 * @param params 查询条件
 */
export async function listShop(params) {
  const res = await request.get("/shop", {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}
