<template>
  <div class="content" id="pjax-container">
    <div class="row items-push">
      <div class="col-md-8">
        <div class="block block-rounded h-100 mb-0">
          <div class="block-header block-header-default">
            <h3 class="block-title">
              <i class="si si-handbag fa-fw me-1 text-muted"></i>
            </h3>
          </div>
          <div class="block-content block-content-full">
            <p class="mb-1">
              <strong>代跑次数介绍</strong>
            </p>
            <p class="mb-1 fs-sm">
              1、购买代跑次数可增加使用本站宥马运动代跑次数，次数所有挂机账号均可享受使用权限。
            </p>
            <p class="mb-1 fs-sm">
              2、推荐一次性购买多次，一次购买多次有优惠哦。
            </p>
            <p class="mb-1 fs-sm text-flat">
              注：系统24小时自动处理订单，支付后自动为您充值代跑次数，无需等待。
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="block block-rounded h-100 mb-0">
          <div class="block-header block-header-default">
            <h3 class="block-title">
              <i class="far fa-money-bill-alt fa-fw me-1 text-muted"></i> 资产
            </h3>
          </div>
          <div class="block-content block-content-full">
            <div class="row items-push text-center">
              <div class="col-6">
                <p class="fs-sm fw-semibold text-uppercase text-muted">余额</p>
                <div class="fs-3 fw-bold">{{ user.money }}元</div>
              </div>
              <div class="col-6">
                <p class="fs-sm fw-semibold text-uppercase text-muted">代跑</p>
                <div class="fs-3 fw-bold">{{ user.ym }}次</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-6 col-xl-3"
        v-for="(shop, index) in shops"
        :key="index"
      >
        <a
          class="block block-link-pop block-rounded block-bordered text-center"
          @click="select_payment(shop.id)"
        >
          <div class="block-header">
            <h3 class="block-title">{{ shop.num }} 次代跑</h3>
          </div>
          <div class="block-content bg-body-light">
            <div class="h1 fw-bold mb-2">￥{{ shop.money }}</div>
            <div class="fw-medium text-muted mb-4">永久</div>
          </div>
          <div class="block-content">
            <p>
              <svg
                t="1643093776526"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="7623"
                width="64"
                height="64"
              >
                <path
                  d="M642.327273 553.890909c-9.309091 9.309091-13.963636 23.272727-9.309091 32.581818l23.272727 148.945455-125.672727-69.818182c-4.654545-4.654545-13.963636-4.654545-18.618182-4.654545-4.654545 0-13.963636 0-18.618182 4.654545l-125.672727 69.818182 23.272727-148.945455c0-13.963636 0-23.272727-9.309091-32.581818L274.618182 446.836364 418.909091 423.563636c13.963636 0 23.272727-9.309091 32.581818-23.272727L512 269.963636l65.163636 130.327273c4.654545 13.963636 18.618182 18.618182 32.581819 23.272727l144.290909 23.272728-111.709091 107.054545zM791.272727 432.872727c-4.654545-13.963636-18.618182-23.272727-32.581818-27.927272l-144.290909-23.272728-65.163636-130.327272c-9.309091-9.309091-23.272727-18.618182-37.236364-18.618182-13.963636 0-27.927273 9.309091-37.236364 23.272727L414.254545 386.327273l-148.945454 23.272727c-13.963636 0-27.927273 9.309091-32.581818 23.272727-4.654545 13.963636 0 32.581818 9.309091 41.890909l107.054545 107.054546-23.272727 144.290909c-4.654545 13.963636 4.654545 27.927273 18.618182 37.236364 4.654545 4.654545 13.963636 9.309091 23.272727 9.30909 4.654545 0 13.963636 0 18.618182-4.654545L512 698.181818l125.672727 69.818182c4.654545 4.654545 13.963636 4.654545 18.618182 4.654545 9.309091 0 13.963636-4.654545 23.272727-9.30909 13.963636-9.309091 18.618182-23.272727 18.618182-37.236364l-23.272727-148.945455 107.054545-107.054545c9.309091-4.654545 13.963636-23.272727 9.309091-37.236364z"
                  fill="#1196db"
                  p-id="7624"
                ></path>
                <path
                  d="M512 1000.727273C242.036364 1000.727273 23.272727 781.963636 23.272727 512S242.036364 23.272727 512 23.272727 1000.727273 242.036364 1000.727273 512 781.963636 1000.727273 512 1000.727273z m0-930.909091c-242.036364 0-442.181818 200.145455-442.181818 442.181818s200.145455 442.181818 442.181818 442.181818 442.181818-200.145455 442.181818-442.181818-200.145455-442.181818-442.181818-442.181818z"
                  fill="#1196db"
                  p-id="7625"
                ></path>
              </svg>
            </p>
            <strong>{{ shop.num }} 份快乐</strong>
          </div>
          <div class="block-content block-content-full">
            <span class="btn btn-primary">
              <i class="fa fa-arrow-up opacity-50 me-1"></i> 购买
            </span>
          </div>
        </a>
      </div>
      -
    </div>
  </div>
</template>

<script>
import { listShop } from "@/api/shop";
import { buy } from "@/api/user";
export default {
  name: "ym",
  inject: ["reloadUser", "reload"],
  data() {
    return {
      shops: {},
      user: {},
    };
  },
  created() {
    this.user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {};
    listShop({
      type: "ym",
    }).then((res) => {
      this.shops = res;
    });
  },
  methods: {
    select_payment(shopid) {
      buy({
        type: "ym",
        id: shopid,
      })
        .then((message) => {
          this.$message.success(message);
          this.reloadUser();
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style scoped></style>
